import { createMemoryHistory, createRouter } from 'vue-router'

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '../pages/HomePage.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ '../pages/LoginPage.vue')
    },
    {
      path: '/image',
      name: 'Image',
      component: () => import(/* webpackChunkName: "image" */ '../pages/DallePage.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

// Add route guard to check login status
router.beforeEach((to, _, next) => {
  const isAuthenticated = localStorage.getItem('isLoggedIn')

  if (to.name === 'Login' && isAuthenticated) {
    next({ name: 'Home' }) // Redirect to HomePage if already authenticated
  } else if (to.name !== 'Login' && !isAuthenticated) {
    next({ name: 'Login' }) // Redirect to login if not authenticated
  } else {
    next() // Proceed to the requested route
  }
})

export default router
